import React, { Fragment } from 'react';
import Container from 'components/UI/Container/Container';
import {Helmet} from 'react-helmet';

const ThingsToDo = props => {
  return (
    <Container>
      	<Helmet>
            <meta charSet="UTF-8"/>
            <title>Things To Do | Paruwa</title>
            <meta name="description" content="Things to do page"/>
    </Helmet>
  <Fragment>
      <h1>Things To Do Page</h1>
  </Fragment>
 </Container>
  )
};

export default ThingsToDo;