import React, { useContext, useState, useEffect}  from 'react';
import { NavLink, Link } from 'react-router-dom';
import Menu from 'components/UI/Antd/Menu/Menu';
import { AuthContext } from 'context/AuthProvider';
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  PRICING_PLAN_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  TRAVEL_ALERTS_PAGE,
  TRAVEL_INSURANCE_PAGE,
  BROUCHERS_PAGE,
  CONTACT_US_PAGE,
  FAQS_PAGE,
  PLAN_YOUR_TRIP_PAGE,
  INSPIRE_YOU_PAGE
} from 'settings/constant';

//const {  Menu  } = antd;
const { SubMenu } = Menu;

const MobileMenu = ({ className,closeMenu} ) => {
  
  const [click, setClick] = useState('');
  

  
  // auth context
  const { loggedIn, logOut } = useContext(AuthContext);
  const clickedItem = () =>{  
    setClick("none"); 
    closeMenu(false); 
  }  

    useEffect(() => { 
    }, [click]);



  return (
    <Menu className={className} mode="inline" >     

      <SubMenu key="sub1"  title=" Destination Guide">          
          <SubMenu key="sub2" title="Top Categories">
            <Menu.Item key="0"><a onClick={clickedItem} href="/listing?beach">Beach</a></Menu.Item>
            <Menu.Item key="1"><a onClick={clickedItem} href="/listing?culture">Culture</a></Menu.Item>
            <Menu.Item key="2"><a onClick={clickedItem} href="/listing?religion" >Religion</a></Menu.Item>
            <Menu.Item key="3"><a onClick={clickedItem} href="/listing?surfing">Surfing</a></Menu.Item>
            <Menu.Item key="4"><a onClick={clickedItem} href="/listing?history">History</a></Menu.Item>
            <Menu.Item key="5"><a onClick={clickedItem} href="/listing?hiking">Hiking</a></Menu.Item>
            <Menu.Item key="6"><a onClick={clickedItem} href="/categories">All Categories</a></Menu.Item>
          </SubMenu>
          <SubMenu key="sub3" title="Top Destinations">
            <Menu.Item key="7"><NavLink onClick={clickedItem} to="/destinations/kandy">Kandy</NavLink></Menu.Item>
            <Menu.Item key="8"><NavLink onClick={clickedItem} to="/destinations/hikkaduwa">Hikaduwa</NavLink></Menu.Item> 
            <Menu.Item key="9"><NavLink onClick={clickedItem} to="/destinations/polonnaruwa">Polonnaruwa</NavLink></Menu.Item> 
            <Menu.Item key="10"><NavLink onClick={clickedItem} to="/destinations/yala">Yala</NavLink></Menu.Item> 
            <Menu.Item key="11"><NavLink onClick={clickedItem} to="/destinations/colombo">Colombo</NavLink></Menu.Item> 
            <Menu.Item key="12"><NavLink onClick={clickedItem} to="/destinations/bentota">Bentota</NavLink></Menu.Item> 
            <Menu.Item key="13"><Link onClick={clickedItem} to="/listing?destination">All Destinations</Link> </Menu.Item>
          </SubMenu>
          <SubMenu key="sub4" title="Our Picks">
            <Menu.Item key="14"> <NavLink onClick={clickedItem} to="/attractions/temple-of-the-sacred-tooth-relic">Temple of Tooth Relic</NavLink></Menu.Item>
            <Menu.Item key="15"> <NavLink onClick={clickedItem} to="/attractions/dambulla-cave-temple">Dambulla Cave Temple</NavLink></Menu.Item> 
            <Menu.Item key="16"> <NavLink onClick={clickedItem} to="/attractions/sigiriya-rock-fortress">Sigiriya Rock Fortress</NavLink></Menu.Item> 
            <Menu.Item key="17"> <NavLink onClick={clickedItem} to="/attractions/yala-national-park">Yala National Park</NavLink></Menu.Item> 
            <Menu.Item key="18"> <NavLink onClick={clickedItem} to="/attractions/nine-arch-bridge">Nine Arch Bridge</NavLink></Menu.Item> 
            <Menu.Item key="19"> <NavLink onClick={clickedItem} to="/attractions/ella-rock">Ella Rock</NavLink></Menu.Item> 
            <Menu.Item key="20"> <Link onClick={clickedItem} to="/listing?attraction">All Attractions</Link> </Menu.Item>
          </SubMenu>
        </SubMenu>

        <SubMenu key="sub5"  title="Things To Do">          
          <SubMenu key="sub6" title="Top Categories">
            <Menu.Item key="21"><NavLink onClick={clickedItem} to="/attractions/ella-rock">Hiking</NavLink></Menu.Item>
            <Menu.Item key="22"><NavLink onClick={clickedItem} to="/attractions/dambulla-cave-temple">Culture</NavLink></Menu.Item> 
            <Menu.Item key="23"><NavLink onClick={clickedItem} to="/attractions/temple-of-the-sacred-tooth-relic" >Religion</NavLink></Menu.Item> 
            <Menu.Item key="24"><NavLink onClick={clickedItem} to="/attractions/kitesurfing-in-kalpitiya">Surfing</NavLink></Menu.Item> 
            <Menu.Item key="25"><NavLink onClick={clickedItem} to="/attractions/unawatuna-beach">Beach</NavLink></Menu.Item> 
            <Menu.Item key="26"><NavLink onClick={clickedItem} to="/attractions/sigiriya-rock-fortress">History</NavLink></Menu.Item> 
            <Menu.Item key="27"><NavLink onClick={clickedItem} to="/categories">All Categories</NavLink></Menu.Item>         
          </SubMenu>          
        </SubMenu>
        <Menu.Item key="28"><NavLink onClick={clickedItem} to={`${PLAN_YOUR_TRIP_PAGE}`}>Plan Your Trip</NavLink></Menu.Item>
        <Menu.Item key="29"><NavLink onClick={clickedItem} to={`${INSPIRE_YOU_PAGE}`}>Inspire You</NavLink></Menu.Item>

        <SubMenu key="sub7"  title="How We Help">
            <Menu.Item key="30"> <NavLink onClick={clickedItem} to={`${TRAVEL_INSURANCE_PAGE}`}>Travel Insurance</NavLink></Menu.Item>
            <Menu.Item key="31"> <NavLink onClick={clickedItem} to={`${BROUCHERS_PAGE}`}>Brochures</NavLink></Menu.Item> 
            <Menu.Item key="32"> <NavLink onClick={clickedItem} to={`${TRAVEL_ALERTS_PAGE}`}>Travel Alerts</NavLink></Menu.Item> 
            <Menu.Item key="33"> <NavLink onClick={clickedItem} to={`${FAQS_PAGE}`}>FAQs</NavLink>            </Menu.Item> 
            <Menu.Item key="34"> <NavLink onClick={clickedItem} to={`${CONTACT_US_PAGE}`}>Contact Us</NavLink></Menu.Item>
        </SubMenu>
      {/* {loggedIn && (
        <Menu.Item key="3">
          <NavLink to={AGENT_ACCOUNT_SETTINGS_PAGE}>Account Settings</NavLink>
        </Menu.Item>
      )}
      {loggedIn && (
        <Menu.Item key="4">
          <button onClick={logOut}>Log Out</button>
        </Menu.Item>
      )} */}
    </Menu>
  );
};

export default MobileMenu;
