import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import Footerimg from './footer_image.jpg';
//import FooterBg from './footer_paruwa.svg';



const FooterWrapper = styled.footer`
  width: 100%;
  // background-color: ${themeGet('color.1', '#ffffff')};
  background-color: #f6f9fc;
  background-image: url(${Footerimg});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  padding: 47px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;


  // .footerTop{   
  //   margin-left: 0px;
  // }
  .footerTopRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 15px;    
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
  }
  .footerTopRow2{
    display: flex;
    justify-content: left;
    width: 100%;
    align-items: baseline;
    padding-top:30px;
  }
  .footerTopRow h3{
   font-size: 18px;
   font-weight: 700;
  }
  .footerTopRow ul li a{
    font-size: 16px;
    color: #2c2c2c;
    line-height: 36px;
   }
   .footerTopRow ul li a:hover{
    color: #008489;
   }
   .footerBottomMenu{
     display: flex;
     margin-bottom: 0px;
   }
   .footerBottomMenu li{
    margin-right: 25px;
  }
  .footerBottomMenu li a{
    color: #2c2c2c;
    font-size: 16px;
  }
  .footerBgImage{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .footerSubscription{
    position: relative;
    margin-bottom: 15px;
  }
  .footerSocial svg{
    font-size: 36px;
    margin-right: 15px;
  }
  
  .footerSubscription input{
    height: 55px;
    line-height: 53px;
    font-size: 14px;
    box-shadow: none;
    border: 1px solid #d7dce3;
    padding: 15px;
    width: 100%;
  }
  .footerSubscription button{
    border: none;
    border-radius: 0 5px 5px 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 55px;
    line-height: 55px;
    color: #fff;
    background: #008489;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: 600;
    text-transform: uppercase;
  }
   .logo_area{
    //  float:right;
     padding-right: 20px;
    // border-right: 1px solid #008489;
   }
   .logo_area a{
    align-items: baseline;
   }
   .footerHr{
    border-top: 1px solid rgba(0, 132, 137, 0.5);
    margin-top: 30px;
   }
   .copyright_text{
   // padding-right: 20px;
    display: flex;
    justify-content: flex-end;
   }
   .copyright_text svg{
    transform: scale(1.5);
    margin-left: 25px;
    }


    @media only screen and (min-width: 569px) and (max-width: 991px) {
      .footerTopRow {
        display: block;
        margin-top: 30px;
      }
      .newsletter{
        margin-top: 30px;
        border-top: 1px solid #7bbfc3;
        padding-top: 30px;
      }
      .footerTopRow2{
        align-items: center;
      }
    }  
   

  @media only screen and (max-width: 575px) {
    background-position:right bottom;
  background-size: 200%;
    padding: 60px 0 50px;
    .footerTopRow {
      display: block;
      margin-top: 30px;
    }
    .footerTop .ant-collapse{
      border: 0px;
      background-color: transparent;
      width: 100%;
    }
    .footerTop .ant-collapse-header{
      padding-left: 0px !important;
      font-size: 18px;
    }
    .footerTop .ant-collapse-content{
      background-color: transparent;
    }
    .footerTop .ant-collapse-content .ant-collapse-content-box{
      padding-left: 5px;
    }
    .footerTop .ant-collapse-content ul li{
      font-size: 16px;
      line-height: 36px;
    }
    .footerTop .ant-collapse-content ul li a{
      color: rgb(86,87,88);
    }
    .footerTop .ant-collapse-content ul li a:hover{
      color: #008489;
    }
    .footerHr{
      margin-top: 20px;
     }

  }
`;

export const MenuWrapper = styled.div`
  margin-top: 40px;
  padding-left: 10px;

  @media only screen and (max-width: 480px) {
    margin-top: 30px;
    padding-left: 0;
  }

  ul,
  .ant-menu,
  ul.ant-menu {
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;

    @media only screen and (max-width: 480px) {
      flex-wrap: wrap;
    }

    li {
      margin: 0 30px;
      padding: 0;
      height: auto;
      margin-bottom: 0 !important;

      @media only screen and (max-width: 480px) {
        margin: 0 7px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.ant-menu-item-selected {
        background-color: transparent;
      }

      color: ${themeGet('text.0', '#2C2C2C')};
      font-size: 15px;
      line-height: 18px;
      font-weight: 700;
      transition: color 0.2s ease-in-out;

      a {
        color: ${themeGet('text.0', '#2C2C2C')};
        transition: color 0.2s ease-in-out;
        &:hover {
          color: ${themeGet('primary.0', '#008489')};
        }
      }
    }
  }
`;

export const CopyrightArea = styled.div`
  color: ${themeGet('text.1', '#909090')};
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  @media only screen and (max-width: 480px) {
    font-size: 14px;    
  }
`;

export const SecondaryFooter = styled.div`
  @media only screen and (max-width: 1200px) {
    height: 74px;
  }
`;

export default FooterWrapper;
