import React from 'react';
import PropTypes from 'prop-types';
import FooterWrapper, {
  MenuWrapper,
  CopyrightArea,
  SecondaryFooter,
} from './Footer.style';
import Row from 'components/UI/Antd/Grid/Row';
import Col from 'components/UI/Antd/Grid/Col';
import Container from 'components/UI/Container/Container';
import { NavLink, Link, withRouter, useLocation } from 'react-router-dom';
import { FaInstagram, FaFacebook, FaTwitter ,FaArrowRight} from 'react-icons/fa';
import useWindowSize from 'library/hooks/useWindowSize';
import footerImg from './footerImg.svg'
import { Collapse } from "antd";

const { Panel } = Collapse;

const Footer = ({ logo, menu, bgSrc, copyright, className, path, match }) => {
  
  let location = useLocation();
  const { width } = useWindowSize();
  const footerType = location.pathname === '/test2' ? '' : 'true';
  return (


    
    <>
    {width > 575 ? (<FooterWrapper id="footer" className={className} >
        <Container className="footerTop">
          <Row className="footerTopRow">
            <Col lg={16} md={24} sm={24}>
              <Row>
              <Col lg={8}  md={8} sm={8}>
              <h3>Community</h3>
              <ul>
                <li><a href="https://blog.paruwa.co.uk/">Blog</a></li>
                <li><Link to="/affiliate">Affiliates</Link></li>
                <li><Link to="/brouchers">Holiday Brochures</Link></li>                              
              </ul>
            </Col>
            <Col  lg={8} md={8} sm={8}>
              <h3>About</h3>
              <ul>
                <li><Link to="/terms">Terms and Conditions</Link></li>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                <li><Link to="/faqs">Help and Support</Link></li>
                <li><Link to="/privacy-policy">Cookies</Link></li>
                <li><Link to="/accessibility">Accessibility</Link></li>                
              </ul>
            </Col> 
            <Col lg={8} md={8} sm={8}>
              <h3>More from Paruwa</h3>
              <ul>                
                <li><Link to="/sri-lanka-visas">Sri Lanka Visas</Link></li>
                <li><Link to="/getting-around">Getting Around</Link></li>
                <li><Link to="/before-travel">Before Travel</Link></li>                          
              </ul>
            </Col>
              </Row>

            </Col>
            <Col lg={8} md={24} sm={24} className="newsletter">
            
                <h3>Sign up for our newsletter</h3>
                <p>Save up to 50% on tours! Get exclusive access to members only deals by email.</p>
                <div className="footerSubscription">
                  <input id="bottomSubscribe" type="text" placeholder="Your Email" /> <button type="submit" name="submit" ><FaArrowRight/></button>
                </div>
                <div className="footerSocial">
              <FaInstagram/><FaFacebook/><FaTwitter/>
              </div>
           
            </Col>
                                     
                     

          </Row>
          <hr className="footerHr"/>
          <Row className="footerTopRow2">
            <Col lg={3} md={4}>{logo && logo}  </Col>
            <Col lg={9} md={8}>
            {copyright && <CopyrightArea>{copyright}</CopyrightArea>}  
            </Col>
            <Col md={12} className="copyright_text">             
                            
              
            </Col>
                           
          
          </Row>
        </Container>
        
        
       
      </FooterWrapper>
      ):(
        <FooterWrapper id="footer" className={className} >
        <Container className="footerTop">
          <Row>
          <Collapse expandIconPosition={["right"]} className="footerCollapseMenu">
          <Panel header="Community" key="1">
            <div>
            <ul>
                <li><a href="https://blog.paruwa.co.uk/">Blog</a></li>
                <li><Link to="/affiliate">Affiliates</Link></li>
                <li><Link to="/brouchers">Holiday Brochures</Link></li>                               
              </ul>
            </div>
          </Panel>
          <Panel header="About" key="2">
            <div>
            <ul>
                <li><Link to="/terms">Terms and Conditions</Link></li>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                <li><Link to="/faqs">Help and Support</Link></li>
                <li><Link to="/privacy-policy">Cookies</Link></li>
                <li><Link to="/accessibility">Accessibility</Link></li>              
              </ul>
            </div>
          </Panel>
          <Panel header="More from Paruwa" key="3">
            <div> <ul>                
                <li><Link to="/sri-lanka-visas">Sri Lanka Visas</Link></li>
                <li><Link to="/getting-around">Getting Around</Link></li>
                <li><Link to="/before-travel">Before Travel</Link></li>                           
              </ul></div>
          </Panel>          
        </Collapse>
          </Row>

          <Row className="footerTopRow">             
                <h3>Sign up for our newsletter</h3>
                <p>Save up to 50% on tours! Get exclusive access to members only deals by email.</p>
                <div className="footerSubscription">
                  <input id="bottomSubscribe" type="text" placeholder="Your Email" /> <button type="submit" name="submit" ><FaArrowRight/></button>
                </div>
                <div className="footerSocial">
              <FaInstagram/><FaFacebook/><FaTwitter/>
              </div> 
          </Row>

          <hr className="footerHr"/>
          <Row className="footerTopRow2">
            <Col md={3}>{logo && logo}  </Col>
            <Col md={9}>
            {copyright && <CopyrightArea>{copyright}</CopyrightArea>}  
            </Col>
            <Col md={12} className="copyright_text">             
                            
              
            </Col>
                           
          
          </Row>
        </Container>
        
        
       
      </FooterWrapper>
      )}
      
      {/* {!!path && <SecondaryFooter />} */}
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.element,
  menu: PropTypes.element,
  bgSrc: PropTypes.string,
  copyright: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Footer;
