import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const BannerWrapper = styled.div`
.home_search_text{
  position: absolute;
  text-align: center;
  top: 38%;
  left: 0;
  right: 0;
}
.home_search_text .search_description{
  color: white;
  font-size: 18px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: ${themeGet('color.1', '#ffffff')};

  @media only screen and (max-width: 991px) {
    > div {
      max-width: 100%;
    }
  }

  .glide,
  .glide__track,
  .glide__slides {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: all;
  }

  .glide__slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .glide__bullets {
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .glide__bullet {
    width: 7px;
    height: 7px;
    background-color: ${themeGet('color.1', '#ffffff')};
    transition: width 0.3s ease;
  }

  .glide__bullet--active {
    width: 25px;
    border-radius: 8px;
    background-color: ${themeGet('color.1', '#ffffff')};
  }

  > div {
    position: relative;
    min-height: 100vh;
  }

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
    pointer-events: none;
    z-index: 0;
  }
  @media only screen and (max-width: 575px) {
    min-height: 550px;

    > div {
      position: relative;
      min-height: 550px;
    }
    .home_search_text{      
      bottom: unset;
      top: 125px;
      
    }
  }
  @media only screen and (min-width: 569px) and (max-width: 991px) {
    .home_search_text{
      top: 100px;
      bottom: unset;
    }
  }
  @media only screen and (min-width: 569px) and (max-width: 991px) and (orientation:portrait){
    .home_search_text{
      top: 38%;
      bottom: unset;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1023px) {
    .home_search_text{
      top: 38%;
      bottom: unset;
    }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1365px) {
    .home_search_text{
      top: 38%;
      bottom: unset;
    }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1365px) and (orientation:portrait){
    .home_search_text{
      top: 38%;
      bottom: unset;
    }
    
  }
  @media only screen and (min-width: 1366px) and (max-width: 1339px) {
    .home_search_text{
      top: 38%;
      bottom: unset;
    }    
  }
  @media only screen and (min-width: 1440px) and (max-width: 1600px) {
    .home_search_text{
      top: 38%;
      bottom: unset;
    } 
    
  }





`;
export const BannerWrapperTest = styled.div`
.home_search_text{
  position: absolute;
  text-align: center;
  top: 45%;
  left: 0;
  right: 0;
}
.home_search_text .search_description{
  color: white;
  font-size: 18px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
  width: 100%;
  height: 650px;
  position: relative;
  background-color: ${themeGet('color.1', '#ffffff')};

  @media only screen and (max-width: 991px) {
    > div {
      max-width: 100%;
    }
  }

  .glide,
  .glide__track,
  .glide__slides {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: all;
  }

  .glide__slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .glide__bullets {
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .glide__bullet {
    width: 7px;
    height: 7px;
    background-color: ${themeGet('color.1', '#ffffff')};
    transition: width 0.3s ease;
  }

  .glide__bullet--active {
    width: 25px;
    border-radius: 8px;
    background-color: ${themeGet('color.1', '#ffffff')};
  }

  > div {
    position: relative;
    height: 650px;
  }

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
    pointer-events: none;
    z-index: 0;
  }
`;

export const SearchWrapper = styled.div`


  padding: 5px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 1px 20px rgba(0,0,0,0.08);
  position: absolute;
  top: 47%;
  left: 0;
  right: 0;
  z-index: 1;
  margin-left: 30px;
  margin-right: 30px;

  .homeTourType{
    padding-left: 28px !important;
  }
  .homeSearchInput::placeholder, .homeTourType::placeholder{
    color: #2C2C2C;
    opacity: 0.7;
  }
  

  @media only screen and (min-width: 1440px) and (max-width: 1600px) {
    left: 30px;
    top: 45%;
    bottom: unset;
    
  }

  @media only screen and (min-width: 1366px) and (max-width: 1439px) {
    left: 30px;
    top: 45%;
    bottom: unset;
    
  }
  @media only screen and (min-width: 1024px) and (max-width: 1365px) {
    left: 30px;
    top: 45%;
    bottom: unset;
    
  }
  @media only screen and (min-width: 1024px) and (max-width: 1365px) and (orientation:portrait){
    left: 30px;
    top: 45%;
    bottom: unset;
    
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1023px) {
    left: 30px;
    top: 45%;
    bottom: unset;
    
  }
  @media only screen and (min-width: 569px) and (max-width: 991px) {
    left: 30px;
    top: 45%;
    bottom: unset;   
  }
  
  @media only screen and (min-width: 569px) and (max-width: 991px) and (orientation:portrait){
    left: 30px;
    top: 45%;
    bottom: unset;    
  }
  

  @media only screen and (max-width: 575px) {

    width: calc(100% - 30px);
    padding: 10px;
    left: 15px;
    margin-left: 0px;
    margin-right: 0px;
    top: 250px;
    bottom: unset;
    

    > p {
      display: none;
    }
  }

  > div {
    @media only screen and (min-width: 481px) {
      // margin-top: 30px;
    }
  }

  h2 {
    margin-bottom: 10px;
  }
`;
export const SearchWrapperTest = styled.div`


  padding: 5px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 1px 20px rgba(0,0,0,0.08);
  position: absolute;
  bottom: 245px;
  left: 0;
  right: 0;
  z-index: 1;


  @media only screen and (min-width: 569px) and (max-width: 991px) {
    width: 414px;
    left: 30px;
  }

  @media only screen and (max-width: 480px) {
    width: calc(100% - 30px);
    padding: 15px;
    left: 15px;

    > p {
      display: none;
    }
  }

  > div {
    @media only screen and (min-width: 481px) {
      // margin-top: 30px;
    }
  }

  h2 {
    margin-bottom: 10px;
  }
`;


export const FormWrapper = styled.div`

display: flex;
  align-items: center;
  justify-content: flex-end;
  // background-color: ${themeGet('color.2', '#F7F7F7')};
  border-radius: 3px 0 0 3px;
  min-height: 60px;


#category_btn{
  font-size: 18px;
}
.category_list li.active{
  background-color:rgba(0,132,137,0.8);
  border: 1px solid rgba(0,132,137,0.5);
  border-radius: 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px 0px;
  color: white;
}
.category_list li.active:hover{
  background-color:rgba(0,132,137,0.8);
}

.category_list li{
  font-size: 16px;
    padding: 5px 10px;
    border: 1px solid;
    border-radius: 2px;
    margin: 0px 5px 5px 0px;
    cursor: pointer;
    float: left;
}
.category_list li:hover{
  background-color:rgba(0,132,137,0.1);
}

#list_drop{
  position: absolute;
    left: 30px;
    width: 40%;
    padding: 15px;
    background-color: white;
    top: 65px;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.3);
    border-top: 1px solid #efefef;
    max-height: 305px;
    overflow: hidden;
  z-index: 999 !important;
}
  
  @media only screen and (max-width: 991px) {
    #list_drop{
      width: 80% !important;
    }
  }
  
.wrapper_hide{
  display: none;
}
.show{
  display: block;
}
.hide{
  display: none;
}
#list_drop ul a{
  color: #2c2c2c;
}
.type_label{
  float: right;
  opacity: 0.6;
  font-size: 14px;
  color: #008489;
}
.dropdown_item{
  padding: 8px 12px;
  display: flex;
  border-radius: 3px;
  width: 100%;
  font-size: 16px;
  position: relative;
  justify-content: space-between;
}
.dropdown_text{
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown_item:hover{
  cursor:pointer;
}
.dropdown_item svg{
  margin-right: 5px;
  height: 13px;
}
textarea:focus, input:focus{
  outline: none;
}
  

  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 480px) {
    //#list_drop {
    //  position: absolute;
    //  left: 15px;
    //  width: calc(100% - 30px);      
    //  z-index: 5;
    //}
  }

  /* submit button style */
  button[type='submit'].ant-btn {
    min-width: 157px;
    height: 60px;
    color: ${themeGet('color.1', '#ffffff')};
    background-color: ${themeGet('primary.0', '#008489')};
    border-radius: 0 3px 3px 0;
    border: 0;
    box-shadow: none;
    font-size: 15px;
    font-weight: 700;
    text-shadow: none;

    @media only screen and (max-width: 991px) {
      min-width: 100%;
      border-radius: 0 0 3px 3px;
    }

    @media only screen and (max-width: 480px) {
      height: 47px;
    }

    &::after {
      display: none;
    }

    &:hover {
      opacity: 0.85;
    }
  }
`;


export const ComponentWrapper = styled.div`

  display: flex;
  width: 100%;
  align-items: center;
  position: relative;

  .target {
    right: 10px;
    left: auto;

    @media only screen and (max-width: 480px) {
      right: 25px;
    }
  }

  /* map auto complete style */
  .map_autocomplete {
    width: 100%;
    position: relative;

    &::after {
      content: '';
      width: 1px;
      height: 20px;
      display: inline-block;
      position: absolute;
      top: calc(50% - 10px);
      right: auto;
      left: auto;
      background-color: ${themeGet('border.3', '#E6E6E6')};
    }

    @media only screen and (max-width: 991px) {
      &::after {
        top: auto;
        bottom: 0;
        height: 1px;
        width: calc(100% - 40px);
        left: 20px;
      }
    }

    @media only screen and (max-width: 480px) {
      &::after {
        width: calc(100% - 30px);
        left: 15px;
      }
    }

    input {
      border: 0;
      font-size: 15px;
      font-weight: 400;
      border-radius: 0;
      height: 60px;
      padding: 0 30px 0 40px;
      background: transparent;
      color: ${themeGet('text.0', '#2C2C2C')};

      @media only screen and (max-width: 480px) {
        height: 47px;
        padding: 0 30px 0 36px;
      }

      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
      }

      &::placeholder {
        color: ${themeGet('text.0', '#2C2C2C')};
      }
    }
  }

  /* date picker style */
  .date_picker {
    min-width: calc(250px - 14px);
    width: calc(100% - 14px);
    margin: 0 0 0 14px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 991px) {
      justify-content: flex-start;
      height: 60px;
    }

    @media only screen and (max-width: 480px) {
      height: 47px;
    }

    .DayPicker__withBorder {
      box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
    }

    .DateRangePicker {
      display: block;
      width: 100%;

      .DateRangePickerInput {
        border: 0;
        padding-right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent;

        .DateRangePickerInput_clearDates,
        .DateRangePickerInput_clearDates {
          &.DateRangePickerInput_clearDates__small {
            display: none;
          }
        }

        .DateInput {
          width: 100%;
          padding: 0 15px 0 30px;
          background: transparent;

          @media only screen and (min-width: 569px) and (max-width: 991px) {
            padding: 0 25px 0 30px;
          }

          @media only screen and (max-width: 568px) {
            padding: 0 15px 0 25px;
          }

          .DateInput_fang {
            display: none;
          }

          .DateInput_input {
            padding: 0;
            font-weight: 400;
            color: ${themeGet('text.0', '#2C2C2C')};
            background: transparent;

            &.DateInput_input__focused {
              border-color: transparent;
            }

            &::placeholder {
              color: ${themeGet('text.0', '#2C2C2C')};
            }
          }
        }

        .DateRangePickerInput_arrow {
          & + .DateInput {
            input {
              text-align: right;
            }
          }
        }
      }

      .DateRangePicker_picker {
        margin-top: -12px;
        z-index: 2;

        @media only screen and (max-width: 991px) {
          top: 47px !important;
        }

        @media only screen and (max-width: 320px) {
          left: -29px !important;
          .DayPicker,
          .DayPicker > div > div,
          .DayPicker > div > div .DayPicker_transitionContainer {
            width: 294px !important;
          }

          .DayPicker {
            .DayPicker_weekHeader {
              padding: 0 !important;
            }

            .CalendarMonth {
              padding: 0 !important;
            }
          }
        }
      }
    }
  }

  /* view with popup component style */
  .view_with__popup {
    max-width: 100%;
    position: relative;

    &::before {
      content: '';
      width: 1px;
      height: 20px;
      display: inline-block;
      position: absolute;
      top: calc(50% - 10px);
      right: auto;
      left: 0;
      z-index: 1;
      background-color: ${themeGet('border.3', '#E6E6E6')};

      @media only screen and (max-width: 991px) {
        top: 0;
        bottom: auto;
        height: 1px;
        width: calc(100% - 40px);
        left: 20px;
      }

      @media only screen and (max-width: 480px) {
        width: calc(100% - 30px);
        left: 15px;
      }
    }

    /* popu handler style */
    .popup_handler {
      width: calc(100% - 14px);
      margin-left: 14px;

      button.ant-btn {
        min-width: auto;
        width: 100%;
        height: 60px;
        border: 0;
        padding: 0 30px;
        border-radius: 0;
        box-shadow: none;
        font-weight: 400;
        font-size: 15px;
        color: ${themeGet('text.0', '#2C2C2C')};
        background-color: ${themeGet('color.2', '#F7F7F7')};

        @media only screen and (max-width: 991px) {
          text-align: left;
          padding: 0 25px 0 30px;
        }

        @media only screen and (max-width: 480px) {
          height: 47px;
          padding: 0 20px 0 25px;
        }

        &::after {
          display: none;
        }
      }
    }

    &.room_guest {
      .popup_handler {
        button.ant-btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    &.active {
      .popup_container {
        margin-top: 0;
        padding: 22px;
        box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);

        @media only screen and (max-width: 991px) {
          margin-top: 0;
        }

        @media only screen and (max-width: 480px) {
          min-width: auto;
        }
      }
    }
  }

  /* icon style */
  > svg {
    position: absolute;
    z-index: 1;
    top: auto;
    &.map-marker,
    &.calendar,
    &.user-friends {
      left: 15px;
      right: auto;
      fill: ${themeGet('primary.0', '#008489')};
    }

    &.calendar {
      @media only screen and (max-width: 480px) {
        width: 14px;
        height: 14px;
      }
    }

    &.user-friends {
      width: 17px;
      height: 17px;
      @media only screen and (max-width: 480px) {
        width: 16px;
        height: 16px;
      }
    }

    &.caret-down {
      right: 12px;
      left: auto;
      fill: ${themeGet('text.1', '#909090')};

      @media only screen and (max-width: 991px) {
        right: 20px;
      }

      @media only screen and (max-width: 991px) {
        right: 15px;
      }
    }
  }
`;

export const RoomGuestWrapper = styled.div`
  width: 100%;

  strong {
    font-size: 15px;
    font-weight: 400;
    color: ${themeGet('text.0', '#2C2C2C')};
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 5px;
  }

  .quantity {
    height: 30px;

    input {
      font-size: 15px;
    }

    button.btn svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export default BannerWrapper;
