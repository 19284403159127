import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { NavLink, Link, withRouter } from 'react-router-dom';
import Menu from 'components/UI/Antd/Menu/Menu';
import Row from 'components/UI/Antd/Grid/Row';
import { FaAngleDown} from 'react-icons/fa';
import Col from 'components/UI/Antd/Grid/Col';
import './MainMenu.css'
import PropTypes from 'prop-types';

import {
HOME_PAGE,
LISTING_POSTS_PAGE,
AGENT_PROFILE_PAGE,
PRICING_PLAN_PAGE,
DESTINATION_GUIDE_PAGE,
THINGS_TO_DO_PAGE,
INSPIRE_YOU_PAGE,
HOW_WE_HELP_PAGE,
PLAN_YOUR_TRIP_PAGE,
TRAVEL_ALERTS_PAGE,
TRAVEL_INSURANCE_PAGE,
BROUCHERS_PAGE,
CONTACT_US_PAGE,
FAQS_PAGE,
DESTINATION_SINGLE_PAGE,
PRIVACY_PAGE,
TERMS_PAGE,
PRIVACY_POLICY_PAGE

} from 'settings/constant';


const MainMenu = ({ className, isLogin, match }) => {
let classes = '';

const [click, setClick] = useState('');
const [showLoading, setShowLoading] = useState(false)

const clickedItem = () =>{  
  setClick("none")
}

useEffect(() => {


}, [click]);


return (
<Menu className={className} id="menu_wrap">
  <Menu.Item key="0" className="dropdown-dis">
  <a className="dropdown"  onMouseEnter={() => setClick("")} >
    Destination Guide <FaAngleDown/>
  </a>
  
  <div className={`dropdown-menu display-none right-6 `} aria-labelledby="dropdownMenuButton" style={{display: click}} onMouseLeave={() => setClick("none")}>
    <Row >
      <Col md={6}>
      <p className="dropdown_menu_title">TOP CATEGORIES</p>
      <ul className="dropdown_menu">
        <li><a href="/listing?beach" onClick={clickedItem} key={5}>Beach</a></li>
        <li><a href="/listing?culture" onClick={clickedItem} key={2}>Culture</a></li>
        <li><a href="/listing?religion" onClick={clickedItem} key={3}>Religion</a></li>
        <li><a href="/listing?surfing" onClick={clickedItem} key={4}>Surfing</a></li>
        <li><a href="/listing?history" onClick={clickedItem} key={6}>History</a></li>
        <li><a href="/listing?hiking" onClick={clickedItem}  key={1} >Hiking</a></li>
        <li className="all_link" onClick={clickedItem}><NavLink to="/categories">All Categories</NavLink></li>
      </ul>
    </Col>
    <Col md={6}>
    <p className="dropdown_menu_title">TOP DESTINATIONS</p>
    <ul className="dropdown_menu">
      <li><NavLink to="/destinations/kandy" onClick={clickedItem} key={7}>Kandy</NavLink></li>
      <li><NavLink to="/destinations/hikkaduwa" onClick={clickedItem} key={8}>Hikaduwa</NavLink></li>
      <li><NavLink to="/destinations/polonnaruwa" onClick={clickedItem} key={9}>Polonnaruwa</NavLink></li>
      <li><Link to="/destinations/yala" onClick={clickedItem} key={10}>Yala</Link></li>
      <li><Link to="/destinations/colombo" onClick={clickedItem} key={11}>Colombo</Link></li>
      <li><Link to="/destinations/bentota" onClick={clickedItem} key={12}>Bentota</Link></li>
      <li className="all_link" onClick={clickedItem}><Link to="/listing?destination">All Destinations</Link></li>
    </ul>
  </Col>
  <Col md={6}l>
  <p className="dropdown_menu_title">OUR PICKS</p>
  <ul className="dropdown_menu">
    <li><NavLink to="/attractions/temple-of-the-sacred-tooth-relic" onClick={clickedItem} key={13}>Temple of Tooth Relic</NavLink></li>
    <li><NavLink to="/attractions/dambulla-cave-temple" onClick={clickedItem} key={14}>Dambulla Cave Temple</NavLink></li>
    <li><NavLink to="/attractions/sigiriya-rock-fortress" onClick={clickedItem} key={15}>Sigiriya Rock Fortress</NavLink></li>
    <li><NavLink to="/attractions/yala-national-park" onClick={clickedItem} key={16}>Yala National Park</NavLink></li>
    <li><NavLink to="/attractions/nine-arch-bridge" onClick={clickedItem} key={17}>Nine Arch Bridge</NavLink></li>
    <li><NavLink to="/attractions/ella-rock" onClick={clickedItem} key={18}>Ella Rock</NavLink></li>
    <li className="all_link" onClick={clickedItem}><Link to="/listing?attraction">All Attractions</Link></li>
  </ul>
</Col>                                    
<Col md={6} className="dropdown_promo">  
<a href="https://blog.paruwa.co.uk/sri-pada-a-trek-to-the-sacred-mountain/">              
  <img src="https://blog.paruwa.co.uk/wp-content/uploads/2020/09/SriPada.jpg"/>
</a>                
<a href="https://blog.paruwa.co.uk/sri-pada-a-trek-to-the-sacred-mountain/"> 
<p className="dropdown_promo_title">RELIGION | PEACE | TREK</p>
</a>
<p className="dropdown_menu_promo_text">A trek to the sacred mountain</p>
</Col>
</Row>
</div>
</Menu.Item>
<Menu.Item key="1" className="dropdown2-dis">
<a onMouseEnter={() => setClick("")} >Things To Do <FaAngleDown/></a>
<div  className={`dropdown-menu2 display-none right-6`} aria-labelledby="dropdownMenuButton" style={{display: click}} onMouseLeave={() => setClick("none")}>
  <Row gutter={20}>
    <Col md={6}>
    <p className="dropdown_menu_title">TOP CATEGORIES</p>
    <ul className="dropdown_menu">
      <li><a href="/listing?beach" onClick={clickedItem} key={5}>Beach</a></li>
      <li><a href="/listing?culture" onClick={clickedItem} key={2}>Culture</a></li>
      <li><a href="/listing?religion" onClick={clickedItem} key={3}>Religion</a></li>
      <li><a href="/listing?surfing" onClick={clickedItem} key={4}>Surfing</a></li>
      <li><a href="/listing?history" onClick={clickedItem} key={6}>History</a></li>
      <li><a href="/listing?hiking" onClick={clickedItem}  key={1} >Hiking</a></li>
      <li className="all_link" onClick={clickedItem}><NavLink to="/categories">All Categories</NavLink></li>                        
    </ul>
  </Col>
  <Col md={6} className="dropdown_promo">
  <a href="https://blog.paruwa.co.uk/5-reasons-to-visit-the-temple-of-the-tooth-relic/">
    <img src="https://blog.paruwa.co.uk/wp-content/uploads/2020/09/toothRelic.jpg"/>
  </a>
  <a href="https://blog.paruwa.co.uk/5-reasons-to-visit-the-temple-of-the-tooth-relic/">
    <p className="dropdown_promo_title">Temple of the tooth relic</p></a>
    <p className="dropdown_menu_promo_text">The home to the Sacred Tooth Relic of the Buddha.</p>                   

  </Col>
  <Col md={6} className="dropdown_promo">
  <a href="https://blog.paruwa.co.uk/underrated-things-to-do-in-colombo/">
    <img src="https://blog.paruwa.co.uk/wp-content/uploads/2020/09/ColomboSL.jpg"/></a>
    <a href="https://blog.paruwa.co.uk/underrated-things-to-do-in-colombo/">
      <p className="dropdown_promo_title">Vivacious Colombo</p></a>
      <p className="dropdown_menu_promo_text">The largest and most cosmopolitan Sri Lankan city</p>
    </Col>                                    
    <Col md={6} className="dropdown_promo">
    <a href="https://blog.paruwa.co.uk/crafts-of-sri-lanka/">
      <img src="https://blog.paruwa.co.uk/wp-content/uploads/2020/09/craftsSL.jpg"/></a>
      <a href="https://blog.paruwa.co.uk/crafts-of-sri-lanka/">
        <p className="dropdown_promo_title"> Craft Sri Lanka</p></a>
        <p className="dropdown_menu_promo_text">Sri Lanka has an amazing range of traditional crafts</p>

      </Col>
    </Row>
  </div>
</Menu.Item>
<Menu.Item key="2">
<NavLink to={`${PLAN_YOUR_TRIP_PAGE}`}>Plan Your Trip</NavLink>
</Menu.Item>
<Menu.Item key="3">
<NavLink to={`${INSPIRE_YOU_PAGE}`}>Inspire You</NavLink>
</Menu.Item>
<Menu.Item key="4" className="dropdown3-dis">
<a onMouseEnter={() => setClick("")} >How We Help <FaAngleDown/></a>
<div  className={`dropdown-menu3 display-none right-6 `} aria-labelledby="dropdownMenuButton" style={{display: click}} onMouseLeave={() => setClick("none")}>
  <Row >
    <Col md={6}>                    
    <ul className="dropdown_menu"> 
      <li onClick={clickedItem}><NavLink to={`${TRAVEL_INSURANCE_PAGE}`}>Travel Insurance</NavLink></li>
      <li onClick={clickedItem}><NavLink to={`${BROUCHERS_PAGE}`}>Brochures</NavLink></li>
      <li onClick={clickedItem}><NavLink to={`${TRAVEL_ALERTS_PAGE}`}>Travel Alerts</NavLink></li>
      <li onClick={clickedItem}><NavLink to={`${FAQS_PAGE}`}>FAQs</NavLink></li>            
      <li onClick={clickedItem}><NavLink to={`${CONTACT_US_PAGE}`}>Contact Us</NavLink></li>
    </ul>
  </Col>               
</Row>
</div>
</Menu.Item>      
</Menu>

);
};

export default withRouter(MainMenu);
