import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }
  html {
    scroll-behavior: smooth;
  }

  ::selection {
    background: ${themeGet('primary.0', '#008489')};
    color: ${themeGet('color.1', '#ffffff')};
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  body {
    font-size: 16px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    line-height: 30px !important;
    font-family: 'Lato', sans-serif;
    -webkit-tap-highlight-color: transparent;
  }

  ul {
    margin: 0;
    padding: 0;
    line-height: 30px !important;
  }

  li {
    list-style-type: none;
    line-height: 30px !important;
  }

  a {
    text-decoration: none;
    line-height: 30px !important;
  }
  p{
    font-size: 18px !important;
    line-height: 30px !important;
  }

  img {
    max-width: 100%;
    height: auto;
  }
  .top_description_wrap h3, .singleDestiContentArea h3{
    font-size: 30px;
    margin-bottom: 25px;
    font-weight: 700;
    line-height: 42px;
    color: #48535c;
    border-left: 3px solid #008489;
    padding-left: 15px;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
    background-color: ${themeGet('color.1', '#ffffff')};

    main.ant-layout-content {
      flex: 1 0 auto;
    }
  }

  .hotel-submission-form {
    .ant-progress-outer {
      position: fixed;
      z-index: 9;
      left: 0;
      top: auto;
    }
    .gm-style {
      > input[type="text"] {
        left: 9px !important;
        top: 46px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .hotel-form-location {
      .ant-card-head-title {
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        color: ${themeGet('text.0', '#2C2C2C')};
      }
      .ant-card-body p {
        display: flex;
        color: ${themeGet('text.2', '#777777')};
        justify-content: flex-start;
        strong {
          color: ${themeGet('text.0', '#2C2C2C')};
          width: 30%;
        }
      }
    }
  }

  .ant-menu-item a::before{
    position: relative !important;
  }

  .ant-popover {
    &.profile_menu {
      z-index: 9999;
    }
    .ant-popover-content {
      .ant-popover-inner {
        .ant-popover-inner-content {
          ul.ant-menu  {
            border: 0;
            &.account_menu {
              li {
                color: ${themeGet('text.0', '#2C2C2C')};
                font-size: 16px;
                line-height: 18px;
                font-weight: 400;
                height: auto;
                transition: color 0.2s ease-in-out;
                &.ant-menu-item-selected,
                &.ant-menu-item-active {
                  background-color: transparent;
                }
                a {
                  padding: 8px 0;
                  color: ${themeGet('text.0', '#2C2C2C')};
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet('primary.0', '#008489')};
                  }
                  &.active {
                    font-weight: 700;
                    color: ${themeGet('primary.0', '#008489')};
                  }
                }
                button {
                  padding: 0;
                  border: 0;
                  cursor: pointer;
                  padding: 8px 0;
                  background-color: transparent;
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet('primary.0', '#008489')};
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .DateRangePicker {
    .DateRangePickerInput {
      .DateRangePicker_picker {
        .DayPicker_weekHeader {
          .DayPicker_weekHeader_ul {
            .DayPicker_weekHeader_li {
              color: ${themeGet('text.0', '#2C2C2C')};
              small {
                font-size: 13px;
              }
            }
          }
        }
        .DayPicker_focusRegion {
          .CalendarMonthGrid {
            .CalendarMonth {
              .CalendarMonth_caption {
                font-size: 16px;
                color: ${themeGet('text.0', '#2C2C2C')};
              }
            }
          }
        }
        .DayPickerNavigation {
          .DayPickerNavigation_button {
            &:focus {
              outline: none;
            }
          }
        }
        .DayPickerKeyboardShortcuts_buttonReset {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .ant-tooltip {
    font-size: 13px;
    font-weight: 700;
    .ant-tooltip-inner {
      box-shadow: none;
      text-align: center;
      border-radius: 3px;
      min-height: 26px;
      padding: 4px 10px;
    }
  }
  .ant-alert{
    font-size: 18px !important;
    line-height: 30px !important;
    padding: 15px !important;
    margin-bottom: 15px !important;
  }

  .ant-select-dropdown {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 12px 12px;
        line-height: 1;
        &.ant-select-dropdown-menu-item-active {
          background-color: rgba(0, 132, 137, 0.1);
        }
      }
    }
  }

  .view_with__popup {
    &.room_guest__component {
      &.active {
        min-height: 54px;
      }
      &.alt {
        .popup_container {
          #popup {
            > div {
              > div {
                padding: 0;
              }
            }
          }
        }
        &.active {
          min-height: inherit;
        }
      }
    }
  }

  .ant-dropdown {
    &.social_share_menu {
      z-index: 9999 !important;
      
      .ant-menu {
        padding: 7px 0;
        min-width: 150px;
        border: 0;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    
        .ant-menu-item {
          margin: 0;
          padding: 0;
          height: inherit;
          line-height: inherit;
          > div {
            padding: 15px 20px;
            color: ${themeGet('text.0', '#2C2C2C')};
            svg {
              margin-right: 8px;
            }
          }

          &:hover {
            background-color: ${themeGet('color.2', '#F7F7F7')};
          }
        }
      }
    }
  }

  .ant-drawer,
  .ant-modal-mask,
  .ant-modal-wrap  {
    z-index: 99999 !important;
  }
  .ant-drawer {
    &.filter_drawer {
      pointer-events: none;
      .ant-drawer-mask {
        display: none;
      }
      &.ant-drawer-bottom {
        &.ant-drawer-open {
          .ant-drawer-content-wrapper {
            box-shadow: none;
          }
        }
      }
      .ant-drawer-content-wrapper {
        height: calc(100vh - 152px) !important;
        pointer-events: all;
        .ant-drawer-wrapper-body {
          height: 100%;
          .ant-drawer-body {
            height: 100%;
            padding-top: 0;
            overflow: hidden;
          }
        }
      }
    }
  }

  .ant-modal-wrap {
    .ant-modal-content {
      box-shadow: 0 1px 10px rgba(0,0,0,0.16);
    }
    &.review_modal {
      .ant-modal {
        max-width: 1170px;
        box-sizing: border-box;
        @media only screen and (max-width: 1260px) {
          padding-left: 30px;
          padding-right: 30px;
        }
        @media only screen and (max-width: 767px) {
          top: 0;
          padding: 0;
          margin-top: 0;
          margin-bottom: 0;
        }
        .ant-modal-body {
          padding-left: 60px;
          padding-right: 60px;
          padding-bottom: 60px;
          padding-top: 30px;
          @media only screen and (max-width: 1260px) {
            padding: 40px 30px;
          }
        }
      }
      .image_uploader{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        @media only screen and (max-width: 667px) {
          flex-wrap: wrap;
        }
        .ant-upload{
          @media only screen and (max-width: 667px) {
            margin-bottom: 10px;
          }
          &.ant-upload-drag{
            border: 0;
            border-radius: 0;
            background: transparent;
            .ant-upload{
              padding: 0;
              display: block;
              width: auto;
              height:auto;
            }
          }
          &.ant-upload-drag-hover{
            .image-drag-area{
              border-color: #48bdc1;
            }
          }
        }
        .ant-upload-list{
          float: none;
          display: flex;
          .ant-upload-list-item{
            width: 125px;
            height: 125px;
            border-radius: 3px;
          }
        }
        .ant-upload-drag-container{
          display: block;
        }
      }
    }
  }

  .gm-style-iw-c {
    box-shadow: none !important;
  }
  .gm-style-iw {
    padding: 0 !important;
    border-radius: 3px !important;
    width: 270px !important;
    z-index: 1;
    .gm-style-iw-d {
      overflow-y: auto !important;
      overflow-x: hidden !important;
      max-width: 100% !important;
    }
    .info_window_card {
      width: 270px;
      margin-bottom: 0;
      > div {
        > img {
          width: 100%;
          height: 190px;
          object-fit: cover;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .content_wrapper {
        > div + div {
          margin-top: 2px;
          margin-bottom: 4px;
        }
        .meta_wrapper {
          .rating {
            margin-top: 5px;
          }
        }
      }
    }
    button[title="Close"].gm-ui-hover-effect {
      width: 26px !important;
      height: 26px !important;
      padding: 6px !important;
      border-radius: 50%;
      margin: 0 !important;
      top: 14px !important;
      right: 11px !important;
      opacity: 1;
      align-items: center;
      display: inline-flex !important;
      background-color: ${themeGet('color.1', '#ffffff')} !important;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16);
      > img {
        margin: 0 !important;
      }
    }
  }

  .ant-modal-wrap {
    &.image_gallery_modal {
      .ant-modal {
        top: 50px;
        .ant-modal-content{
          box-shadow: none;
          padding: 0 30px;
          background: transparent;
          @media only screen and (max-width: 480px) {
            padding: 0 20px;
          }
          .ant-modal-body {
            max-width: 1170px;
            padding: 0;
            margin: 0 auto; 
            @media only screen and (max-width: 1440px) {
              position: relative;
            }
          }
        }
        .image_gallery_close {
          background: transparent;
          border: 0;
          width: 60px;
          height: 60px;
          position: absolute;
          top: -20px;
          right: 40px;
          &:focus,
          &:hover {
            outline: none;
          }
          @media only screen and (max-width: 1440px) {
            top: 0;
            right: 0;
            svg {
              path {
                opacity: 0.8;
                fill: ${themeGet('color.1', '#ffffff')};
                transition: all 0.3s ease;
              }
            }
            &:hover {
              svg {
                path {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  .quantity {
    button.btn {
      border: 1px solid ${themeGet('primary.0', '#008489')};
      svg {
        fill: ${themeGet('primary.0', '#008489')};
      }
      &:hover {
        background-color: ${themeGet('primary.0', '#008489')};
        svg {
          fill: #ffffff;
        }
      }
    }
    input[type="number"],
    input[type="number"].qnt-input {
      color: ${themeGet('text.0', '#2C2C2C')};
    }
  }

  .mobile-header {
    &.ant-drawer {
      z-index: 10000;
    }
    .ant-drawer-body {
      padding: 0;
      .auth-menu {
        border: 0;
        display: flex;
        margin: 0 0 30px;
        padding: 25px 20px;
        align-items: center;
        background-color: ${themeGet('color.2', '#F7F7F7')};
        li {
          height: auto;
          line-height: 1;
          padding: 0;
          margin: 0;
          &.ant-menu-item-selected,
          &.ant-menu-item-active {
            background-color: transparent;
          }
          a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 12px 15px;
            border-radius: 3px;
            font-size: 16px;
            font-weight: 700;
            color: ${themeGet('text.0', '#2C2C2C')};
          }
          &:last-child {
            a {
              color: ${themeGet('color.1', '#ffffff')};
              background-color: ${themeGet('primary.0', '#008489')};
              transition: opacity 0.2s ease;
              &:hover {
                opacity: 0.9;
              }
            }
          }
        }
      }
      .main-menu {
        border: 0;
        padding-top: 30px;
        li {
          padding: 0;
          &.ant-menu-item-selected,
          &.ant-menu-item-active {
            background-color: transparent;
          }
          a {
            font-size: 16px;
            padding: 0 31px;
            border-left: 4px solid transparent;
            color: ${themeGet('text.0', '#2C2C2C')};
            &.active {
              font-weight: 700;
              border-color: ${themeGet('primary.0', '#008489')};
              color: ${themeGet('primary.0', '#008489')};
            }
          }
          button {
            display: block;
            text-align: left;
            width: 100%;
            border: 0;
            padding: 0 32px;
            cursor: pointer;
            background-color: transparent;
            color: ${themeGet('text.0', '#2C2C2C')};
            transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            &:hover {
              color: ${themeGet('primary.0', '#008489')};
            }
            &focus {
              outline: 0;
            }
          }
          &:hover {
            a {
              color: ${themeGet('primary.0', '#008489')};
            }
          }
        }
      }
    }
  }

  .pac-container {
    border: 0;
    z-index: 99999;
    padding: 5px 0;
    margin-top: 0;
    border-radius: 3px;
    box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
    @media only screen and (max-width: 991px) {
      margin-top: 0;
    }
    &::after {
      display: none;
    }
    .pac-item {
      border-top: 0;
      line-height: 30px;
      padding: 10px 14px;
      cursor: pointer;
      font-size: 13px;
      color: ${themeGet('text.1', '#909090')};
      .pac-icon {
        margin-top: 6px;
      }
      .pac-item-query {
        font-size: 16px;
        font-weight: 600;
        color: ${themeGet('text.0', '#2C2C2C')};  
        .pac-matched {
          font-weight: 700;
          color: ${themeGet('color.0', '#000000')};
        }
      } 
      &:hover {
        background-color: ${themeGet('color.2', '#F7F7F7')};
      }
    }
  }

  .reservation_modal {
    .ant-modal {
      width: 100% !important;
      max-width: 450px;
      padding: 0 15px;
    }
    .ant-modal-content {
      box-shadow: none;
      .ant-modal-body {
        padding: 0;
        position: relative;
        .reservation_sidebar {
          box-shadow: 0 1px 10px rgba(0,0,0,0.16);
          header {
            padding-top: 50px;
            padding-bottom: 20px;
            border-color: ${themeGet('border.0', '#EBEBEB')};
            @media only screen and (max-width: 375px) {
              padding-top: 30px;
            }
          }
          .DateInput__small {
            width: 112px;
          }
          input,
          .DateRangePickerInput {
            padding: 0 9px;
          }
          footer {
            border-color: ${themeGet('border.0', '#EBEBEB')};
          }
        }
        > button.close {
          border: 0;
          padding: 0;
          top: 15px;
          right: 15px;
          height: auto;
          line-height: 1;
          position: absolute;
          font-size: 32px;
          background-color: transparent;
          color: ${themeGet('text.1', '#909090')};
          transition: all 0.3s ease;
          @media only screen and (max-width: 375px) {
            top: 10px;
            right: 10px;
            font-size: 25px;
          }
          &:hover,
          &:focus {
            outline: none;
            color: ${themeGet('text.0', '#2C2C2C')};
          }
          &::after {
            display: none;
          }
        }
      }
    }
  }


  .reservation_sidebar_desti{
    header{
      margin-bottom: 0px !important;
      background-color: #008489;
      padding: 15px 30px 14px 30px !important;
      margin-top: -60px;
      h2{
        color: white;
      }
    }

  }
  .reservation_sidebar {
    padding: 0 !important;
    background-color: ${themeGet('color.1', '#ffffff')};
    header {
      padding-bottom: 27px;
      margin-bottom: 29px;
      padding: 25px 30px 26px 30px;
      border-bottom: 1px solid ${themeGet('border.0', '#EBEBEB')};
      @media only screen and (max-width: 375px) {
        padding: 25px 15px 26px 15px;
      }
      a {
        &:hover {
          color: #31b8bd;
        }
      }
    }

    p {
      color: ${themeGet('text.0', '#2C2C2C')};
      font-size: 16px;
      font-weight: 400;
      a {
        color: ${themeGet('primary.0', '#008489')};
        font-weight: 700;
        &:hover,
        &:focus {
          outline: 0;
          color: #008489d1;
          text-decoration: underline;
        }
      }
    }

    footer {
      padding: 25px 30px 28px 30px;
      margin-top: 29px;
      border-top: 1px solid ${themeGet('border.0', '#EBEBEB')};
      @media only screen and (max-width: 375px) {
        padding: 20px 15px 25px 15px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .grid_column .placeholder {
    max-width: 100%;
  }

  .singlePageLoader{
    transform: scale(2) !important;
    margin-top: 500px !important;
    margin-bottom: 450px !important;
  }
  .singlePageLoaderWrapper{
    display: flex;
    justify-content: center;
  }

  //Inner page Styles

  .innerPageWrap{
    padding: 25px 0px;
  }
  .innerPageWrap p{
    font-size: 18px;
    line-height: 32px;
  }
  .innerPageWrap ul{
    padding-left: 25px;
  }
  .innerPageWrap ul li{
    list-style: disc;
    font-size: 18px !important;
    line-height: 30px !important;
  }
  .innerPageWrap ol li{
    list-style: disc;
    font-size: 18px !important;
    line-height: 30px !important;
  }
  .innerPageWrap .section_title{
    margin-bottom: 25px;
    margin-top: 25px;
    font-weight: 300;
    border-bottom: 1px solid #b7b7b7;
    padding-bottom: 15px;
    font-size: 22px;
  }
  .innerPageWrap h2{
    border-bottom: 1px solid #efefef;
    padding-bottom: 10px;
    font-size: 20px;
    margin-top: 40px;
  }

  .innerPageWrap h3{
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 700;
    line-height: 42px;
    color: #48535c;
    border-left: 0px;
    padding-left: 0px
  }
  .innerPageWrap img{
    margin-bottom: 15px;
  }
  .innerPageWrap ol li {
    list-style-type: unset;
  }
  .innerPageWrap .cardsWrap .ant-card{
    margin-bottom: 30px;
  }
  .cardsWrap .ant-card-meta-title p{
    margin-bottom: 0px;
  }
  .contactUsForm{
    margin-top: 40px;
  }




// Contact us page

.contactUsPageForm input{
  min-height: 40px;
  font-size: 16px;
}
.contactUsPageForm textarea{
  min-height: 80px;
  font-size: 16px;
}
.contactUsFormWrapper h2{
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 5px;
}
.contactUsFormWrapper p{
  font-size: 18px;
  font-weight: 500;
}
.contactUsPage{
  padding-top: 60px;
}
.adultsGroup{
  display: flex;
  justify-content: space-evenly;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 50px;
}
.childSection{    
    justify-content: space-evenly;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 25px;
}
childSectionTitle{ 
  display: flex;
  justify-content: left;
}
.dateSection .expandDiv .ant-form-item{
  margin-bottom: 0px;
  display: inline-block;
}
.currencySection .ant-form-item{
  margin-bottom: 0px;
}
// .currencySection{
//   display: flex;
// }

.ageGroup .ant-form-item{
  margin-bottom: 10px;
  display: block;
  text-align: center;
}
.ageGroup .ant-input-number{
  position: absolute;
  top:0;
  left: 0;
  width: 68px !important;
  height: auto !important;
  border: none;
  border-bottom: 2px solid #008489;
  border-radius: 0px;
}

.ageGroup .ant-input-number-handler-wrap{
  width: auto !important;
  position: relative !important;
  opacity: 1;
}

.ageGroup .ant-input-number-handler-up {
  position: absolute !important; 
  border: 1px solid #d9d9d9 !important;
  cursor: pointer;
  right: -22px !important;
  width: 22px !important;
  top: -1px !important;
  height: 37px !important;
}
.ageGroup .ant-input-number-handler-up:hover {  
  height: 37px !important;
}
.ageGroup .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner, .ageGroup .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner{
  font-size: 13px;
}
.ageGroup .ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -7px;
  text-align: center;
}

.ageGroup .ant-input-number-handler-down {
  top: -1px !important;
  border: 1px solid #d9d9d9 !important;
  border-bottom-right-radius: 2px !important;
  cursor: pointer;
  left: -23px!important; 
  height: 37px !important;
  width: 22px !important;
}
.ageGroup  .ant-input-number-handler-down:hover {  
  height: 37px !important;
}

 .noValue .ant-input-number-handler.ant-input-number-handler-down{
  opacity: 0 
}
 .hasValue .ant-input-number-handler.ant-input-number-handler-down{
  opacity: 1 
}

.ant-form-item-children{
  position: initial !important;
}

.ageGroup .ant-input-number-handler {
  position: absolute !important;
  display: block;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.budgetSection .ant-input-number{
    width: 120px !important;
}
.budgetSection .ant-form-item{
  display: inline-flex !important;
  margin-bottom: 25px;
}

.budgetinputWrap{
  margin-left: 20px;
  margin-right: 25px;
}
.noOfDays{
  margin-top: 10px;
}
.noOfDays p{
  margin-bottom: 5px;
  
}
.noOfDays .ant-form-item{  
  margin-left: 22px !important;
}
.howHearSection .ant-radio-wrapper{
  max-height: 60px !important;
}

.planTripSubmit{
  margin-left:3rem;
}


.currencyRow{
  display: flex !important;
}











.ant-input-number-input {
  width: 100%;
  height: 35px !important;
  padding: 0 11px;
  text-align: center !important;
  background-color: transparent;
  border: 0;
  font-size: 18px !important;
}

.childGroupWrap a {
  color: #008489;
  text-decoration: underline;
  font-size: 16px;
}
.formSectionheader{
  position:relative;
  padding-left: 3rem;
  text-align: left;

}
.enquire-progress-icon{
  position:absolute;
  left: 0;
}
.sectionForm{
  margin-top: 20px;
  padding: 25px;
  background-color: white;
  box-shadow: 0px 2px 31px 0px #e6e6e6;
  margin-left: 3rem;
}
.formSection{
  padding-bottom: 40px;
    padding-top: 5px;
}
.requiredField{
  position:relative;
}
.requiredField:before{
  content: " ";
    border-left: 4px solid #e6e6e6;
    display: block;
    position: absolute;
    bottom: 0;
    left: 14px;
    height: -webkit-calc(100% - 3rem);
    height: calc(100% - 3rem);
}
.enquire-progress-icon {
  font-size: 1em;
  line-height: 1;
  -webkit-transition: color 0.16s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: color 0.16s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.icon-wrap svg{
  fill:#e6e6e6;
  width: 34px;
  height: 38px;
}




.ant-radio-wrapper{
  display: flex !important;
    align-items: center !important;
    height: 80px !important;
}
.ant-radio-wrapper span{
  font-size: 18px;
  padding-left: 15px !important;
}
.ant-radio-inner::after {
  top: 4px !important;
  left: 4px !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 20px !important;
}
.ant-radio-inner {
  width: 30px !important;
  height: 30px !important;
}
.ant-radio-checked::after {
  left: 15px !important;
  width: 30px !important;
}
.plr0{
  padding-left: 0px !important; 
  padding-right: 0px !important; 
}
.expandDiv{
  margin-left: 60px;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
}
.expandDiv .ant-calendar-picker-large{
  width: 100%;
  margin-bottom: 15px;
}
.categoryItemWrap{
  display: block;
}
#category_item{
  text-align: center;
    max-height: 60px;
    max-width: 60px;
    float: left;
    text-align: center;   
}
#category_item span{
  display: block;
  height: 64px;
  width: 64px;
  padding: 5px;
  border: 2px solid transparent;
  border-radius: 50%;
}
#category_item:hover span {
  cursor: pointer;
  border-color: #008489;
}
#category_item:hover p {
  text-decoration: underline;
}
#category_item img{
  max-height: 40px;
  max-width: 40px;
}
#category_item p{
  line-height: 1;
  margin-bottom: 0px;
}
.contactUsPage button.ant-btn.ant-btn-primary {
  height: 55px;
  min-width: 200px;
  border-color: #008489;
  background-color: #008489;
  text-shadow: none;
  font-size: 15px;
  font-weight: 700;
  border-radius: 3px;
  line-height: 1;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-checked #category_item span{
  background-color: rgba(0, 132, 137, 0.5);
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-checked #category_item p{
  text-decoration: underline;
}


// #category_item.active span{
//   background-color: rgba(0, 132, 137, 0.5);
// }
// #category_item.active p{
//   text-decoration: underline;
// }


.ageSection .ant-form-item-label label{
  font-size: 18px !important;
}
.childSection{
  display: none;
}
.childSectionTitle{
  display: none;
  font-size: 20px;
  font-weight: 700;
}
.currencySection .ant-radio{
  display: none;
}
.ant-form-item-label > label::after{
  display: none;
}
.currencySection .currency-check-box input {
  position: fixed;
  top: 0;
  left: -9999px;
}
.currency-check-box{
  text-align: center;
}
.currencySection .currency-check-box:hover  {
  cursor: pointer;
}
.currencySection .currency-check-box img {
  opacity: 0.2;
    -webkit-filter: saturate(0%);
    filter: saturate(0%);
    display: block;
    margin: auto;
    width: 50px;
    pointer-events: none;
}

.currencySection .ant-radio-wrapper-checked .currency-check-box  img {
  opacity: 1;
  -webkit-filter: none;
  filter: none;
  width: 55px;
}

.checkboxGroup .ant-checkbox{
  display: none;
}








  //home Destinatins
.is_transparent a h3{
  color: #fff;
}





  .home_destinations .image_card .imageCardContent, .attraction_slider_wrap .image_card .imageCardContent, .categories_page_wrap .imageCardContent {
    text-align: left;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 140px;
    padding: 15px;
  }


  .home_destinations .image_card .imageCardContent h4, .attraction_slider_wrap .image_card .imageCardContent h4, .categories_page_wrap .imageCardContent h4,
  .home_destinations .image_card .imageCardContent p, .attraction_slider_wrap .image_card .imageCardContent p, .categories_page_wrap .imageCardContent p {
	-webkit-transform: translate3d(0,80px,0);
  transform: translate3d(0,80px,0);
  font-size: 15px;
  line-height: 22px;
}
.home_destinations .image_card .imageCardContent h4, .attraction_slider_wrap .image_card .imageCardContent h4, .categories_page_wrap .imageCardContent h4 {
	-webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  text-align:left;
  font-weight: 500;
  font-size: 18px;
}

.home_destinations .image_card .imageCardContent p, .attraction_slider_wrap .image_card .imageCardContent p, .categories_page_wrap .imageCardContent p{
	color: rgba(255,255,255,0.8);
	opacity: 0;
	-webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
	transition: opacity 0.2s, transform 0.35s;
}

.home_destinations .image_card:hover p, .attraction_slider_wrap .image_card:hover p , .categories_page_wrap .image_card:hover p{
	opacity: 1;
}

.home_destinations .image_card:hover h4, .attraction_slider_wrap .image_card:hover h4, .categories_page_wrap .image_card:hover h4,
.home_destinations .image_card:hover p, .attraction_slider_wrap .image_card:hover p, .categories_page_wrap .image_card:hover p {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

.home_destinations .image_card:hover p, .attraction_slider_wrap .image_card:hover p, .categories_page_wrap .image_card:hover p {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
	-webkit-transition-duration: 0.35s;
	transition-duration: 0.35s;
}
.view_with__popup.active .popup_container{
  margin-top: 20px !important;
}

.popup_container #popup::before{
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: block;
  height: 12px;
  width: 12px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -6px;
  left: 50px;
  background: rgb(255,255,255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(230,230,230);
  border-image: initial;
  border-right: 0px;
  border-bottom: 0px;
}

  //Categories Page
  

  .categories_page_wrap{
    padding: 50px 30px;
  }

  .categories_page_wrap .image_card{
    margin-bottom: 30px;
  }
  // .categories_page_wrap .tallCard{
  //   height: 500px;
  // }
  // .categories_page_wrap .tallCard a{
  //   height: 500px;
  // }
  .categories_page_wrap .imageCardContent{
    position: absolute;
    bottom: 25px;
    left: 20px;
    right: 20px;
  }
  // .categories_page_wrap .imageCardContent h4{
  //   font-size: 18px;
  //   text-align: left;
  //   font-weight: 400;
  // }
  // .categories_page_wrap .imageCardContent p{
  //   font-size: 24px;
  //   text-align: left;
  //   font-weight: 700;
  //   line-height: 32px;
  // }


  // FAQ Page Styles
  .header_image_text{
    padding-top: 30px;
  }
  .header_image_text h1 {
    font-weight: 500;
    font-size: 48px;
    color: white;
    text-shadow: 0px 0px 5px black;
    margin-bottom: 0px;
    line-height: 72px;
}
  .innerPageBreadcrumb{
    margin-bottom: 0px;
  }
  .innerPageBreadcrumb{
    display: flex;
  }
  .innerPageBreadcrumb li{
    color: white;
    margin-right: 10px;
    font-size: 18px;
    text-shadow: 0px 0px 5px black;
    line-height: 32px;
  }
  .innerPageBreadcrumb li a{
    color: white;
    margin-right: 10px;
    font-size: 18px;
    text-shadow: 0px 0px 5px black;
    line-height: 32px;
  }
  
.faqPageWrap h1{
  padding: 15px !important;
  margin-bottom: 10px;
  margin-top: 50px;
  border-left: 3px solid #008489;
  background-color: rgba(0, 132, 137, 0.05);
  font-size: 25px;
}
.alertContent p{
  margin-bottom: 0px !important;
}
  .faqCollapse{
    background-color: transparent !important;
    border: 0px !important;
  }
  .faqCollapse .ant-collapse-item-active .ant-collapse-header{
    background-color: #008489;
    color: white !important;
  }
  .faqCollapse .ant-collapse-header {
    padding: 20px 15px !important;
    padding-right: 40px;
    font-size: 20px;
    font-weight: 300;
}
.faqCollapse .ant-collapse-content > .ant-collapse-content-box div{
  font-size: 18px !important;
  line-height: 30px;

}
.faqCollapse ul li{
  list-style: disc !important;
  font-size: 18px !important;
}
.faqCollapse ul {
  padding-left: 20px !important;
}
.faqCollapse .ant-collapse-content-box p{
  font-size: 18px;
  line-height: 30px;
}
.checkboxGroup .ant-checkbox-wrapper{
  font-size: 18px !important;
  text-align: left !important;
  padding: 0 !important;
  margin: 0 25px 50px!important;
}
.ant-checkbox-inner{
  width: 30px !important;
  height: 30px !important;
}
.ant-input {
  font-size: 18px;
  height: 48px; 
}

.adultsGroupWrap h3{
  text-align: left;
  font-size: 20px;
  font-weight: 700;
}
.childGroupWrap {
  justify-content: left;
  text-align: left;
}

.ant-radio-wrapper span{
  text-align: left;
}


.budgetSection .ant-checkbox-wrapper span{
  width: 85% !important;
  float: left !important;
  font-size:18px;
}
.budgetSection .ant-checkbox-wrapper span:first-child{
  width: 36px !important;
  float: left !important;

}
 .icon-wrap.green svg {
  fill: #10b363;
}
.icon-wrap.red svg {
  fill: #ff0000;
}
.icon-wrap.orange svg {
  fill: #ffa500;
}
.ant-calendar-input-wrap {
  display: none;
}
select {
  text-transform: none;
  height: 40px;
  width: 47%;
  margin-right: 2.5%;
  padding-left: 10px;
  font-size: 16px;
}
select option{
  font-size: 16px;
  padding-left: 10px;
}

.formBasicDetails .ant-form-item-label > label{
  height: 48px;
  font-size: 18px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
  font-size: 18px;
}

.formBasicDetails input    {
  min-height: 40px;
font-size: 16px;
}





@media only screen and (min-width: 576px) and (max-width: 991px) {
  .categories_page_wrap .image_card a{
    height: 250px;
  }
}

@media only screen and (max-width: 575px) { 
  .ageSection .ant-form-item label {
    position: relative;
    margin-left: 15px;
}
  .ageGroup .ant-input-number{
    width: 80% !important;
  }
  .ant-calendar{
    margin-left: auto;
    margin-right: auto;
  }
  .ant-calendar-range {
    width: 90% !important;
}
.ant-calendar-range-part {
    width: 100% !important;
}
  .childSection{
    margin-right: 0px;
  }

  .categories_page_wrap{
    padding: 25px !important;
  }
  .faqPageWrap h1{
    margin-top: 25px;
  }
  .contactUsPage{
    padding-top: 25px;
  }
  .innerPageWrap h2{
    margin-top: 0px;
  }

  .header_image_text h1 {
    margin-top: 15px !important;
    font-size: 28px !important;
    line-height: 36px !important;
  }
  
  
  .view_with__popup.active .popup_container{
    margin-top: 10px !important;
  }
  .ant-menu{
    color: #2c2c2c !important;
  }
  .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    color: #2c2c2c;
    border-bottom: 1px solid #f3f3f3;
}

.mobile-header .ant-drawer-body .main-menu li a{
  padding-left: 0px;
  border-left: 0px;
}
.mobile-header .ant-drawer-body .main-menu li:hover a{
  color: #2c2c2c;
}
.mobile-header .ant-drawer-body .main-menu li:hover a.active{
  color: #008489;
}
.where_to_eat{
  padding-top:0px !important;
}

.ant-form-item .ant-form-item-label {
  min-width: 150px;
}
.adultsGroup{
  display: block !important;
  margin-left: 0px  !important;
  margin-right: 0px  !important;
}

.ageGroup{
  position: relative;
    display: block;
    width: 100%;
    
}
.ageGroup .ant-form-item{
  display: flex !important;
}
.ant-radio-wrapper span {
  font-size: 18px;
  padding-left: 15px !important;
  white-space: normal;
  text-align: left;
  float: left;
  width: 85%;
  margin-bottom: 15px;
}
.ant-radio-wrapper span:first-child {
  float: left;
  width: 30px;
  height: 30px;
  margin-left: -20px;
    margin-right: 10px;
}

.requiredField:before{
    content: " ";
    border-left: 4px solid #e6e6e6;
    display: inline-block;
    height: 3rem;
    margin-top: -1rem;
    margin-bottom: 0.25rem;
    position: relative;
    left: 0;
    
}
.requiredField{  
  text-align: center;
  position: relative;
}


.enquire-progress-icon {
  position: relative;
  text-align: center;
}
.formSectionheader{
  padding-left: 0px;
}
.formSection .ant-radio-wrapper{
  display: block !important;
}
.sectionForm{
  margin-left: 0px;
}
.planTripSubmit{
  margin-left: 0px;
}

.childSection{
  margin-left: 0px;
}
.childGroupWrap h3{
  justify-content: center;
}
.budgetSection .ant-input-number{
  position: relative;
}

.formSection{
  text-align: center;
}
.budgetSection .ant-checkbox-wrapper span{
  width: 85% !important;
  float: left !important;
  text-align: left;
  font-size: 16px;
}
.budgetSection .ant-checkbox-wrapper span:first-child{
  width: 36px !important;
  float: left !important;
  margin-top: 8px;
}
.budgetSection .ant-checkbox-checked::after{
  border: 0px !important;
}
.formSectionheader{
  text-align: center;
}
.adultsGroupWrap h3{
  text-align: center;
}
.childGroupWrap{
  text-align: center;
}
.expandDiv {
  margin-left: 0px;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  position: relative;
  margin-top: 0px;
  margin-bottom: 18px;
  display: inline-table;
}
.expandDiv .ant-input-number{
  position: relative;
}

.expandDiv  .ant-form-item {
  margin-left: 0px !important;
}


.currencyRow{
  justify-content: center;
  display: inline-block !important;
}
.currencyRow .ant-radio-wrapper {
  float: left;
  width: 30%;
  justify-content: center;
}





  
}




`;

export default GlobalStyle;
