import React from 'react';
import { NavLink } from 'react-router-dom';
import Menu from 'components/UI/Antd/Menu/Menu';

import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  PRIVACY_PAGE,
  PRICING_PLAN_PAGE,
  AGENT_PROFILE_PAGE,
  DESTINATION_GUIDE_PAGE,
  THINGS_TO_DO_PAGE,
  INSPIRE_YOU_PAGE,
  HOW_WE_HELP_PAGE,
  PLAN_YOUR_TRIP_PAGE,
  PRIVACY_POLICY_PAGE,
  FAQS_PAGE,
  TERMS_PAGE
} from 'settings/constant';

const FooterMenu = () => {
  return (
    <Menu>
      <Menu.Item key="0">
        <NavLink to={`${FAQS_PAGE}`}>Help</NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to={`${PRIVACY_POLICY_PAGE}`}>Privacy Policy</NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to={`${TERMS_PAGE}`}>Terms & Conditions</NavLink>
      </Menu.Item>     
    </Menu>
  );
};

export default FooterMenu;
