import React, { useContext } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { AuthContext } from './context/AuthProvider';
import Layout from './container/Layout/Layout';
import {
  HOME_PAGE_TEST,
  HOME_PAGE_TEST2,
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  FORGET_PASSWORD_PAGE,
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  SINGLE_POST_PAGE,
  ADD_HOTEL_PAGE,
  AGENT_PROFILE_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  PRIVACY_POLICY_PAGE,
  PRICING_PLAN_PAGE,
  ATTRACTION_SINGLE_PAGE,
  DESTINATION_SINGLE_PAGE,
  DESTINATION_GUIDE_PAGE,
  THINGS_TO_DO_PAGE,
  INSPIRE_YOU_PAGE,
  PLAN_YOUR_TRIP_PAGE,
  PLAN_YOUR_TRIP_PAGE2,
  HOW_WE_HELP_PAGE,
  TESTING_SINGLE_PAGE,
  TRAVEL_INSURANCE_PAGE,
  BROUCHERS_PAGE,
  CONTACT_US_PAGE,
  TRAVEL_ALERTS_PAGE,
  FAQS_PAGE,
  TERMS_PAGE,
  CATEGORIES_PAGE,
  PRIVACY_PAGE,
  KEYWORD_RESULTS_PAGE,
  Accessibility_PAGE,
  Affiliate_PAGE,
  Cookies_PAGE,
  SriLankaVisas_PAGE,
  GettingAround_PAGE,
  BeforeTravel_PAGE,
  FormTest_PAGE

} from './settings/constant';
import ThingsToDo from 'container/SamplePages/ThingsToDo';

/**
 *
 * Public Routes
 *
 */
const Loading = () => null;

const routes = [
  {
    path: HOME_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Home" */ './container/Home/Home'),
      loading: Loading,
      modules: ['Home'],
    }),
    exact: true,
  },
  {
    path: HOME_PAGE_TEST,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Home" */ './container/Home/HomeTest'),
      loading: Loading,
      modules: ['HomeTest'],
    }),
    exact: true,
  },
  {
    path: HOME_PAGE_TEST2,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Home" */ './container/Home/HomeTest2'),
      loading: Loading,
      modules: ['HomeTest2'],
    }),
    exact: true,
  },

  {
    path: LOGIN_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "SignIn" */ './container/SignIn/SignIn'),
      loading: Loading,
      modules: ['SignIn'],
    }),
  },
  {
    path: REGISTRATION_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "SignUp" */ './container/SignUp/SignUp'),
      loading: Loading,
      modules: ['SignUp'],
    }),
  },
  {
    path: FORGET_PASSWORD_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ForgetPassWord" */ './container/ForgetPassWord/ForgetPassWord'
        ),
      loading: Loading,
      modules: ['ForgetPassWord'],
    }),
  },
  // {
  //   path: `${SINGLE_POST_PAGE}/:slug`,
  //   component: Loadable({
  //     loader: () =>
  //       import(
  //         /* webpackChunkName: "SinglePageView" */ './container/SinglePage/SinglePageView'
  //       ),
  //     loading: Loading,
  //     modules: ['SinglePageView'],
  //   }),
  // },
  {
    path: LISTING_POSTS_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Listing" */ './container/Listing/Listing'),
      loading: Loading,
      modules: ['Listing'],
    }),
  },
  {
    path: KEYWORD_RESULTS_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Listing" */ './container/Listing/Results'),
      loading: Loading,
      modules: ['Results'],
    }),
  },
  {
    path: AGENT_PROFILE_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "AgentDetailsViewPage" */ './container/Agent/AccountDetails/AgentDetailsViewPage'
        ),
      loading: Loading,
      modules: ['AgentDetailsViewPage'],
    }),
  },

  {
    path: PRICING_PLAN_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ './container/Pricing/Pricing'),
      loading: Loading,
      modules: ['Pricing'],
    }),
  },

  /*Custom Page Design paths*/
  {
    path: `${ATTRACTION_SINGLE_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SinglePageView" */ './container/SinglePage/SingleAttraction'
        ),
      loading: Loading,
      modules: ['SingleAttraction'],
    }),
  },
  {
    path: `${DESTINATION_SINGLE_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SinglePageView" */ './container/SinglePage/SinglePageView'
        ),
      loading: Loading,
      modules: ['SinglePageView'],
    }),
  },
  {
    path: `${TESTING_SINGLE_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SingleDestinationTest" */ './container/SinglePage/SingleDestinationTest'
        ),
      loading: Loading,
      modules: ['SingleDestinationTest'],
    }),
  },
  {
    path: DESTINATION_GUIDE_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "destinations_guide" */ './container/SamplePages/DestinationGuide'),
      loading: Loading,
      modules: ['DestinationGuide'],
    }),
  },
  {
    path: HOW_WE_HELP_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "how_we_help" */ './container/SamplePages/HowWeHelp'),
      loading: Loading,
      modules: ['HowWeHelp'],
    }),
  },
  {
    path: THINGS_TO_DO_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "things_to_do" */ './container/SamplePages/ThingsToDo'),
      loading: Loading,
      modules: ['ThingsToDo'],
    }),
  },
  {
    path: PLAN_YOUR_TRIP_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "plan_your_trip" */ './container/SamplePages/PlanYourTrip'),
      loading: Loading,
      modules: ['PlanYourTrip'],
    }),
  },
  {
    path: PLAN_YOUR_TRIP_PAGE2,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "plan_your_trip" */ './container/SamplePages/PlanYourTrip2'),
      loading: Loading,
      modules: ['PlanYourTrip2'],
    }),
  },
  {
    path: INSPIRE_YOU_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "inspire_you" */ './container/SamplePages/InspireYou'),
      loading: Loading,
      modules: ['InspireYou'],
    }),
  },

  {
    path: TRAVEL_INSURANCE_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "travel_insurance" */ './container/SamplePages/TravelInsurance'),
      loading: Loading,
      modules: ['TravelInsurance'],
    }),
  },
  {
    path: BROUCHERS_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "brouchers" */ './container/SamplePages/Brouchers'),
      loading: Loading,
      modules: ['Brouchers'],
    }),
  },
  {
    path: FAQS_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "faqs" */ './container/SamplePages/Faqs'),
      loading: Loading,
      modules: ['Faqs'],
    }),
  },
  {
    path: TRAVEL_ALERTS_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "travel_alerts" */ './container/SamplePages/TravelAlerts'),
      loading: Loading,
      modules: ['TravelAlerts'],
    }),
  },
  {
    path: CONTACT_US_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "contact_us" */ './container/SamplePages/ContactUs'),
      loading: Loading,
      modules: ['ContactUs'],
    }),
  },
  {
    path: TERMS_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "terms_and_conditions" */ './container/SamplePages/TermsAndConditions'),
      loading: Loading,
      modules: ['TermsAndConditions'],
    }),
  },
  {
    path: PRIVACY_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "privacyPage" */ './container/SamplePages/PrivacyPage'),
      loading: Loading,
      modules: ['PrivacyPage'],
    }),
  },
  {
    path: CATEGORIES_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "categories" */ './container/SamplePages/Categories'),
      loading: Loading,
      modules: ['CategoriesPage'],
    }),
  },
  {
    path: Accessibility_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "categories" */ './container/SamplePages/Accessibility'),
      loading: Loading,
      modules: ['Acessibility'],
    }),
  },
  {
    path: Affiliate_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "categories" */ './container/SamplePages/Affiliate'),
      loading: Loading,
      modules: ['Affiliate'],
    }),
  },
  {
    path: Cookies_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "categories" */ './container/SamplePages/Cookies'),
      loading: Loading,
      modules: ['Cookies'],
    }),
  },
  {
    path: SriLankaVisas_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "categories" */ './container/SamplePages/SriLankaVisas'),
      loading: Loading,
      modules: ['SriLankaVisas'],
    }),
  },
  {
    path: GettingAround_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "categories" */ './container/SamplePages/GettingAround'),
      loading: Loading,
      modules: ['GettingAround'],
    }),
  },
  {
    path: BeforeTravel_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "categories" */ './container/SamplePages/BeforeTravel'),
      loading: Loading,
      modules: ['BeforeTravel'],
    }),
  },
  {
    path: FormTest_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "categories" */ './container/SamplePages/FormTest'),
      loading: Loading,
      modules: ['FormTest'],
    }),
  },

  


];

/**
 *
 * Protected Route Component
 *
 */

const AddHotel = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RenderListingForm" */ './container/Listing/RenderListingForm'
    ),
  loading: Loading,
  modules: ['RenderListingForm'],
});

const AgentAccountSettingsPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AgentAccountSettingsPage" */ './container/Agent/AccountSettings/AgentAccountSettingsPage'
    ),
  loading: Loading,
  modules: ['AgentAccountSettingsPage'],
});

/**
 *
 * Not Found Route Component
 *
 */

const NotFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: "NotFound" */ './container/404/404'),
  loading: Loading,
  modules: ['NotFound'],
});

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { loggedIn } = useContext(AuthContext);
  return (
    <Route
      render={props =>
        loggedIn ? <Component {...props} /> : <Redirect to={LOGIN_PAGE} />
      }
      {...rest}
    />
  );
};

/**
 *
 * Overall Router Component
 *
 */

const Routes = () => {
  return (
    <Layout>
      <Switch>
        {routes.map(({ path, component, exact = false }) => (
          <Route key={path} path={path} exact={exact} component={component} />
        ))}
        <ProtectedRoute path={ADD_HOTEL_PAGE} component={AddHotel} />
        <ProtectedRoute
          path={AGENT_ACCOUNT_SETTINGS_PAGE}
          component={AgentAccountSettingsPage}
        />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
};

export default Routes;
