// **************** ROUTE CONSTANT START **************************
// General Page Section
export const HOME_PAGE = '/';
export const HOME_PAGE_TEST = '/test';
export const HOME_PAGE_TEST2 = '/test2';
export const AGENTS_PAGE = '/agents';

// Listing Single Page Section
export const LISTING_POSTS_PAGE = '/listing';
export const KEYWORD_RESULTS_PAGE ='/results'
//export const SINGLE_POST_PAGE = '/post';
export const SINGLE_POST_PAGE = '/attractions';
export const DESTINATION_SINGLE_PAGE = '/destinations';
export const ATTRACTION_SINGLE_PAGE = '/attractions';
export const TESTING_SINGLE_PAGE = '/destination_test';


// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = '/profile';
export const AGENT_ACCOUNT_SETTINGS_PAGE = '/account-settings';
export const AGENT_PROFILE_EDIT_PAGE = '/edit';
export const AGENT_IMAGE_EDIT_PAGE = '/change-image';
export const AGENT_PASSWORD_CHANGE_PAGE = '/change-password';
export const AGENT_PROFILE_DELETE = '/delete';
export const AGENT_PROFILE_FAVOURITE = '/favourite-post';
export const AGENT_PROFILE_CONTACT = '/contact';
export const ADD_HOTEL_PAGE = '/add';

// Other Pages
export const PRICING_PLAN_PAGE = '/pricing-plan';
export const PRIVACY_POLICY_PAGE = '/privacy';
export const TERMS_PAGE = '/terms';
export const CATEGORIES_PAGE = '/categories'

// New Pages
export const DESTINATION_GUIDE_PAGE = '/destination-guide';
export const THINGS_TO_DO_PAGE = '/things-to-do';
export const PLAN_YOUR_TRIP_PAGE = '/plan-your-trip';
export const PLAN_YOUR_TRIP_PAGE2 = '/plan-your-trip2';
export const INSPIRE_YOU_PAGE = '/inspire-you';
export const HOW_WE_HELP_PAGE = '/how-we-help';
export const PRIVACY_PAGE = '/privacy-policy';

export const BROUCHERS_PAGE = '/brouchers';
export const CONTACT_US_PAGE = '/contact-us';
export const FAQS_PAGE = '/faqs';
export const TRAVEL_ALERTS_PAGE = '/travel-alerts';
export const TRAVEL_INSURANCE_PAGE = '/travel-insurance';
export const Accessibility_PAGE = '/accessibility';
export const Affiliate_PAGE = '/affiliate';
export const Cookies_PAGE = '/cookies';
export const SriLankaVisas_PAGE = '/sri-lanka-visas';
export const GettingAround_PAGE = '/getting-around';
export const BeforeTravel_PAGE = '/before-travel';
export const FormTest_PAGE = '/form-test';




// Login / Registration Page
export const LOGIN_PAGE = '/login';
export const REGISTRATION_PAGE = '/registration';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';
// **************** ROUTE CONSTANT END **************************
