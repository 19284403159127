import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { FaMapMarkerAlt ,FaSearch} from 'react-icons/fa';
import Button from 'components/UI/Antd/Button/Button';
import { MapDataHelper } from 'components/Map/MapLocationBox';
import ViewWithPopup from 'components/UI/ViewWithPopup/ViewWithPopup';
import { setStateToUrl } from 'library/helpers/url_handler';
import {  ATTRACTION_SINGLE_PAGE, DESTINATION_SINGLE_PAGE, KEYWORD_RESULTS_PAGE } from 'settings/constant';
import search_feed from 'data/search_feed.json'
import {
  FormWrapper,
  ComponentWrapper,
} from '../../Home/Search/Search.style';

const calendarItem = {
  separator: '-',
  format: 'MM-DD-YYYY',
  locale: 'en',
};

const SearchForm = ({ history }) => {


const [searchTerm, setSearchTerm, setInputText,  ] = React.useState("");
const [searchResults, setSearchResults,] = React.useState([]);
const handleChange = event => {

    setSearchTerm(event.target.value);
    const oldStyle = document.getElementById('list_drop').className;
    // const newClassName = oldStyle === 'show' ? 'hide' : 'show'
    document.getElementById('list_drop').className =  'show'
 

  };
 
 React.useEffect(() => {
    const results = listItems.filter(item =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);

    if(results.length == 0){
      document.getElementById('list_drop').className =  'hide'
    }
  }, [searchTerm]);

  const clickHandle = e =>{
    // setSearchResults(event.target.value)    
    const clickedItemClass = e.currentTarget.className ;
    const NewClass = clickedItemClass ==='' ? 'active' : ''
    e.currentTarget.className = NewClass;

    const value = e.currentTarget.getAttribute('dataValue'); 

   // document.getElementById('search_id').value = selectedValue;
    // toggleClass();
    //tInputText(selectedValue);
    setCategories(value);    
  }

  const [listItems, setItem] = useState([]);

  useEffect(() =>{
    // fetch(destinations)
    //   //.then(res => res.json())
    //   .then(res => {
    //     setItem(res);
    //   })      
    //   .catch( e =>{
    //   })
    setItem(search_feed);
  })

  const [searchDate, setSearchDate] = useState({
    setStartDate: null,
    setEndDate: null,
  });
  const [mapValue, setMapValue] = useState([]);

  // Room guest state
  const [roomGuest, setRoomGuest] = useState({
    room: 0,
    guest: 0,
  });

  // Room guest state
  const [categories, setCategories] = useState([]);


  const updatevalueFunc = event => {
    const { searchedPlaceAPIData } = event;
    if (!isEmpty(searchedPlaceAPIData)) {
      setMapValue(searchedPlaceAPIData);
    }
  };

  // Room Guest data handling
  const handleIncrement = type => {
    setRoomGuest({
      ...roomGuest,
      [type]: roomGuest[type] + 1,
    });
  };

  const handleDecrement = type => {
    if (roomGuest[type] <= 0) {
      return false;
    }
    setRoomGuest({
      ...roomGuest,
      [type]: roomGuest[type] - 1,
    });
  };

  const handleIncDecOnChnage = (e, type) => {
    let currentValue = e.target.value;

    setRoomGuest({
      ...roomGuest,
      [type]: currentValue,
    });
  };

  const addShowClass =() =>{
    // const oldStyle = document.getElementById('list_drop').className;
    // const newClassName = oldStyle === 'show' ? 'hide' : 'show'
    // document.getElementById('list_drop').className =  "show"
  }

  const goToSearchPage = () => {
    let tempLocation = [];
    const mapData = mapValue ? MapDataHelper(mapValue) : [];
    mapData &&
      mapData.map((singleMapData, i) => {
        return tempLocation.push({
          formattedAddress: singleMapData ? singleMapData.formattedAddress : '',
          lat: singleMapData ? singleMapData.lat.toFixed(3) : null,
          lng: singleMapData ? singleMapData.lng.toFixed(3) : null,
        });
      });
    const location = tempLocation ? tempLocation[0] : {};
    const query = {
      date_range: searchDate,
      room: roomGuest.room,
      guest: roomGuest.guest,
      location,
      categories,
    };
    const search = setStateToUrl(query);
    const submitValue = document.getElementById('search_id').value;
    history.push({
      pathname: KEYWORD_RESULTS_PAGE,
      search: submitValue,
      categories
    });
  };

  const styles={
    width: '100%',
    height: '100%',
    border: '0px',
    padding: '15px  40px 15px 15px' ,
    backgroundColor: 'transparent',
    fontSize: '18px',
  } 
  
    const linkattr = ATTRACTION_SINGLE_PAGE;

    const linkDes = DESTINATION_SINGLE_PAGE;

  return (
    <FormWrapper className="navbarSearchField">
      <ComponentWrapper>
       <input className="homeSearchInput" id="search_id" type="text" placeholder="Where you want to go?" style={styles}  value={searchTerm}
        onChange={handleChange} autoComplete="off"/> <FaSearch id="main_search_icon" className="map-marker"/>
      </ComponentWrapper>
  
        <div id="list_drop" className="wrapper_hide">
          <ul>
            {searchResults.map(item =>(
                <Link to={`${item.type =='Attraction'? linkattr : linkDes}/${item.slug}`} key={item.id}><li  className="dropdown_item"  ><div className="dropdown_text"> <FaMapMarkerAlt className="map-marker"/> {item.title}</div> <div className="type_label">{item.type}</div></li>
                </Link>
            ))}

              <li id="no_results" className="hide"> No Results Found!</li>
          </ul>
        </div>
      {/* <Button
        type="primary"
        htmlType="submit"
        size="large"
        onClick={goToSearchPage}
      >
        Search
      </Button> */}
    </FormWrapper>
  );
};

export default withRouter(SearchForm);
